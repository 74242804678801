.carousel {
  display: flex;
  min-height: 78vh;
}

.skills {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  height: 206px;

  ul {
    padding: 0;
  }

  .listOfSkills {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .mainSkills {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
  }

  .skillPicture {
    width: 150px;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .hardSkills {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 0;
  }

  .skill {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 206px;

  }

  li {
    list-style: none;
  }

  .hard {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  .hardTitle {
    align-self: center;
    margin-bottom: 0;
    margin-top: 20px;
  }

  .frontSkills,
  .devSkills,
  .environementSkills,
  .librairiesSkills {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .hardskill {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 0;
    margin-bottom: 20px;
    font-size: 17px;

    .skill {
      width: auto;
      height: auto;
    }
  }

  .skillSkill {
    font-size: 14px;
    font-weight: 700;
  }
}

@media screen and (max-width: 576px) {
  .skills {
    justify-content: center;

    .mainSkills {
      display: flex;
    }

    .frontSkills,
    .devSkills,
    .environementSkills,
    .librairiesSkills {
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }

    .listOfSkills {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    img {
      width: 150px;
    }

    .skillSkill {
      font-size: 12px;
      font-weight: 400;
    }

    .hardSkills {
      margin-top: 0;
    }
  }
}

@media screen and (min-width: 1200px) {
  .skills {
    .listOfSkills {
      width: 70%;
    }
  }
}
