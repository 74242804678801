form {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  background-color: #c1837a;
  justify-content: center;
  height: 78vh;

  #name,
  #email,
  #number,
  #message {
    margin-bottom: 20px;
    border: 2px;
  }

  .champ {
    display: flex;
    flex-direction: column;
  }
  input {
    border-radius: 2px;
    height: 50px;
  }
  #message {
    height: 100px;
  }
  .submitBtn {
    background-color: #983122;
    color: #fff;
    padding: 15px;
    border: none;
    cursor: pointer;
  }
}
@media screen and (max-width: 768px) {
}
@media screen and (min-width: 1024px) {
  form {
    .champ {
      flex-direction: row;
      width: 100%;
    }
    label {
      width: 40%;
      text-align: center;
      align-self: center;
    }
    #name,
    #email,
    #number,
    #message {
      width: 40%;
    }
  }
}
