body {
  color: #5c413c;
  margin: 0;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  height: 100vh;
  ul {
    list-style: none;
  }
  a {
    color: #5c413c;
    text-decoration: none;
  }
  a:hover {
    color: #983122;
  }
  a:focus {
    color: #983122;
  }
  .footer {
    z-index: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
    margin-bottom: 0;
    border-top: solid 3px #983122;
    margin-top: 0;
  }
}
@media screen and (max-width: 576px) {
  body {
    .footer {
      margin: 0;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
