.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #dfc0bc;
  height: 78vh;
}

.desole,
.chatConstruction {
  font-size: 30px;
  font-family: "Roboto", sans-serif;
}
.github {
  color: #5c413c;
  text-decoration: underline;
}
