.header {
  font-family: Arial, Helvetica, sans-serif;

  .logoAndNav {
    display: none;
  }

  .logoAndNav-responsive {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .hideLinks {
    display: none;
  }

  .hideBurger {
    display: none;
  }

  .hideLogo {
    display: none;
  }

  .logo {
    border: solid 2px #983122;
    margin: 10px;
  }

  .showBurger {
    display: flex;
  }

  .showLinks {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: space-between;
    background-color: #983122;
    position: inherit;
    z-index: 200;
    padding: 50px;
  }

  .showLogo {
    display: block;
  }

  #hiddenMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .menuList {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 43px;
  }

  .clickOnBurger {
    cursor: pointer;
    text-decoration: none;
    border: none;
    background-color: #fff;
  }

  li {
    padding: 15px;
    cursor: pointer;
    list-style: none;
    text-decoration: none;
    font-size: 20px;
  }

  a {
    color: #fff;
    margin: 0;
    text-decoration: none;
  }

  a:hover {
    color: #5c413c;
  }

  button {
    z-index: 400;
    height: 100%;
    margin: 0 10px;
    border: solid 1px #983122;
    background-color: #fff;
  }
}

@media screen and (min-width: 768px) {
  .header {
    .logoAndNav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
    }

    .logoAndNav-responsive {
      display: none;
    }

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    a {
      color: #5c413c;
    }

    a:hover {
      color: #983122;
    }

    li {
      font-size: 16px;
    }
  }
}

@media screen and (min-width: 992px) {
  .header {
    li {
      font-size: 20px;
    }
  }
}
