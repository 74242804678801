.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #dfc0bc;
  height: 78vh;
}

.desole,
.chatConstruction {
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  text-align: center;
}
.gitLink:active,
.gitLink:visited {
  text-decoration: none;
}
