.PhotoEtPrez {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  .photoElisa {
    width: 40%;
    margin: 0;
    margin-left: 30px;
  }

  .presentation {
    margin: 30px;
    margin-right: 30px;
    line-height: 1.8;
    text-align: justify;
    font-size: 15px;
  }

  .contacter {
    text-decoration: underline;
    color: #5c413c;
    margin: 0;
  }
}

@media screen and (max-width: 576px) {
  .PhotoEtPrez {
    flex-direction: column;
  }
}

@media screen and (min-width: 768px) {
  .PhotoEtPrez {
    .photoElisa {
      width: 22%;
    }
  }
}

@media screen and (min-width: 1024px) {

}

@media screen and (min-width: 1400px) {
  *
  .PhotoEtPrez {
    .photoElisa {
      width: 31%;

    }

    .presentation {
      width: 16%;
    }
  }
}
